import { createSelector } from "reselect";
import { getSelectedStopoverCityId } from "app/pages/Booking/bookingSelectors";

export const getOngoingSDPProducts = state => state.smartDP.accommodationsList;
export const getAvailableStopoverCities = state => state.smartDP.stopoverCities;
export const getSDPProductsPageSize = state => state.smartDP.accommodationsPageSize;
export const getSDPQuotationItems = state => state.smartDP.quotationItems;
export const getSDPFlights = state => state.smartDP.flights;
export const getActiveSort = state => state.smartDP.sortedBy;
export const getRecommendedSortActivated = state => state.smartDP.recommendedSortActivated;

export const calculateSDPBasePrice = createSelector(
	[getSDPQuotationItems],
	(quotationItems = []) => {
		let total = 0;

		quotationItems.forEach(quotationItem => {
			total += quotationItem.price;
		});

		return total;
	}
);

export const getSelectedStopoverCity = createSelector(
	[getAvailableStopoverCities, getSelectedStopoverCityId],
	(availableStopoverCities = [], selectedStopoverCityId) => {
		return availableStopoverCities.find(city => city.code === selectedStopoverCityId);
	}
);
